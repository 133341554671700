
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      width: 800,
      height: 500,
    };
  },
});
