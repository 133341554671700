
import { defineComponent } from "vue";
import Header from "@/components/projects/webgl/Header.vue";
import Canvas from "@/components/projects/webgl/Canvas.vue";
import Controls from "@/components/projects/webgl/Controls.vue";
import { init, loadCharacter } from "@/utils/projects/webgl/webgl";

export default defineComponent({
  components: {
    Header,
    Canvas,
    Controls,
  },
  data() {
    return {
      file_: "teapot.obj",
    };
  },
  mounted() {
    init();
    this.loadCharacter();
  },
  computed: {
    file: {
      get(): string {
        return this.file_;
      },
      set(file: string) {
        this.file_ = file;
        this.loadCharacter();
      },
    },
  },
  methods: {
    loadCharacter() {
      loadCharacter(this.file);
    },
  },
});
