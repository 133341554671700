import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73bf8b1e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("canvas", {
      id: "webgl-canvas",
      width: _ctx.width,
      height: _ctx.height
    }, " Your browser does not support HTML5 canvas ", 8, _hoisted_1)
  ]))
}