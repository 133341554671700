import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Canvas = _resolveComponent("Canvas")!
  const _component_Controls = _resolveComponent("Controls")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createVNode(_component_Header),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Canvas),
      _createVNode(_component_Controls, {
        modelValue: _ctx.file,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.file) = $event))
      }, null, 8, ["modelValue"])
    ])
  ]))
}